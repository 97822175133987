import { quality } from "../../../helpers/image";
import AlertBox from "../../../mixins/AlertBox";
export default {
  mixins: [AlertBox],
  data() {
    return {
      actionIsLoading: false,
      changedImage: {
        image: null,
        file: null,
      },
    };
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    SUBMIT_CONTENT(e) {
      e.preventDefault();
      if (this.form.id) this.contentPut(this.form.id, this.form);
      else this.contentCreate(this.form);
    },
    async uploadImage(e, width = 250) {
      const file = e.target.files[0];
      const response = await quality(file, width, 1);
      this.changedImage = { image: response.result, file: response.file };
    },
    async contentPut(id, body) {
      this.SET_LOADING_MODAL(true);
      console.log(body, "body", this.form);

      // convert object to formdata
      const fd = new FormData();
      for (const key in body) {
        const d = this.forceProps[key] || body[key];
        if (d != null && d != "null") {
          console.log(d, this.forceProps, key);
          fd.append(key, d || "-");
        }
      }
      if (this.changedImage.file)
        fd.append("contentImage", this.changedImage.file);
      // about type'inde ekleme yapılırsa.

      if (this.degrees?.length > 0 && this.$route.query.type == 2) {
        if (this.degrees.filter((d) => d.name).length > 0) {
          const degreesString = `<ul class='lab-ul'>${this.degrees
            .map((d) => `<li>${d.name}</li>`)
            .join("")}</ul>`;
          fd.delete("degrees");
          fd.append("degrees", degreesString);
        }
      } else if (this.degrees?.length === 0 && this.$route.query.type == 2) {
        fd.delete("degrees");
        fd.append("degrees", "-");
      }

      const response = await this.$apiHeocademy.put("Contents/" + id, fd);
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"), 3000);
        this.$store.commit("SETTINGS_IS_LOADED", {
          settings: this.getSettings,
          status: false,
        });
        this.$store.dispatch("GET_COMPANY_SETTINGS");
        this.changedImage = {
          file: null,
          image: null,
        };
      } else this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"), 3000);
    },
    async contentDelete(id, callback) {
      this.SET_LOADING_MODAL(true);

      const response = await this.$apiHeocademy.delete("Contents/" + id);
      if (response.message === "OK") {
        if (callback) callback();
        this.SET_ALERT_BOX("success", this.$t("deleteIsSucceed"), 3000);
        this.$store.commit("SETTINGS_IS_LOADED", {
          settings: this.getSettings,
          status: false,
        });
        this.$store.dispatch("GET_COMPANY_SETTINGS");
        this.changedImage = {
          file: null,
          image: null,
        };
      } else this.SET_ALERT_BOX("danger", this.$t("deleteIsNotSucceed"), 3000);
    },
    async contentCreate(body, callback) {
      this.SET_LOADING_MODAL(true);
      console.log(body, "body");
      // convert object to formdata
      const fd = new FormData();
      for (const key in body) {
        const d = this.forceProps[key] || body[key];
        fd.append(key, d || "-");
      }
      if (this.changedImage.file)
        fd.append("contentImage", this.changedImage.file);

      if (this.degrees?.length > 0 && this.$route.query.type == 2) {
        if (this.degrees.filter((d) => d.name).length > 0) {
          const degreesString = `<ul class='lab-ul'>${this.degrees
            .map((d) => `<li>${d.name}</li>`)
            .join("")}</ul>`;
          fd.delete("degrees");
          fd.append("degrees", degreesString);
        }
      } else if (this.degrees?.length === 0 && this.$route.query.type == 2) {
        fd.delete("degrees");
        fd.append("degrees", "");
      }
      if (this.$route.query.type) fd.append("Type", this.$route.query.type);
      const response = await this.$apiHeocademy.post(
        "Contents?companyId=" + this.getSettings.id,
        fd
      );
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("insertIsSucceed"), 3000);
        if (callback) callback(response.data);
        this.$store.commit("SETTINGS_IS_LOADED", {
          settings: this.getSettings,
          status: false,
        });
        this.$store.dispatch("GET_COMPANY_SETTINGS");
        this.changedImage = {
          file: null,
          image: null,
        };
      } else this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"), 3000);
    },
  },
};
